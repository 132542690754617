<template>
  <div class="container">
    <TemplateListRecently
      title="Вы ранее просматривали коллекции"
      type="collections"
      class-root="last-seen-products-block"
      :per-page="4"
    >
      <template v-slot="slotData">
        <div class="container">
          <template v-if="$viewport.isGreaterOrEquals('lg')">
            <div class="last-seen-products-block__items">
              <template
                v-for="(product) in slotData.products"
                :key="`product-${product.id}`"
              >
                <CollectionCard
                  v-if="Boolean(product.characteristicGroupId === 19)"
                  :product="product"
                />
                <ProductCard
                  v-if="Boolean(product.characteristicGroupId !== 19)"
                  :product="product"
                />
              </template>
            </div>
          </template>
          <template v-if="!$viewport.isGreaterOrEquals('lg')">
            <MobileSwiper
              :key="`MobileSwiper-${keyMobileSwiperCollection}`"
              :products="slotData.products"
            />
          </template>
        </div>
      </template>
    </TemplateListRecently>
    <TemplateListRecently
      title="Вы ранее просматривали товары"
      type="products"
      class-root="last-seen-products-block"
      :per-page="4"
    >
      <template v-slot="slotData">
        <div class="container">
          <template v-if="$viewport.isGreaterOrEquals('lg')">
            <div class="last-seen-products-block__items">
              <template
                v-for="(product) in slotData.products"
                :key="`product-${product.id}`"
              >
                <CollectionCard
                  v-if="Boolean(product.characteristicGroupId === 19)"
                  :product="product"
                />
                <ProductCard
                  v-if="Boolean(product.characteristicGroupId !== 19)"
                  :product="product"
                />
              </template>
            </div>
          </template>
          <template v-if="!$viewport.isGreaterOrEquals('lg')">
            <MobileSwiper
              :key="`MobileSwiper-${keyMobileSwiperProducts}`"
              :products="slotData.products"
            />
          </template>
        </div>
      </template>
    </TemplateListRecently>
  </div>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import { Product } from '#sitis/internal/controllers/products/models/Product';
import { onMounted, ref } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import generateId from '~/utils/generateId';

const ProductCard = defineAsyncComponent(() => import('~/components/catalog/ProductCard.vue'));
const CollectionCard = defineAsyncComponent(() => import('~/components/catalog/CollectionCard.vue'));
const MobileSwiper = defineAsyncComponent(() => import("~/components/select-products-block/MobileSwiper.vue"));
const TemplateListRecently = defineAsyncComponent(() => import("~/components/common/TemplateListRecently.vue"));

const { $viewport } = useNuxtApp();

const keyMobileSwiperProducts: Ref<string> = ref(String(generateId(3)));
const keyMobileSwiperCollection: Ref<string> = ref(String(generateId(3)));
</script>

<style lang="scss">
.last-seen-products-block {
  padding: 62px 0;
  box-sizing: border-box;
}
.last-seen-products-block__head {
  margin-bottom: 32px;
}
.last-seen-products-block__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -32px;
  margin-left: -32px;

  & > * {
    width: calc(100% / 4 - 32px);
    margin-top: 32px;
    margin-left: 32px;
  }
}

@media (max-width: 1699px) {
  .last-seen-products-block__items {
    & > * {
      width: calc(100% / 3 - 32px);
      &:nth-child(4) {
        display: none;
      }
    }
  }
}
</style>
